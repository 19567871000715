@import '../../../../styles/mixins/element-mixins';
@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/mixins/font-mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  @include desktop-bp {
    flex-direction: row;
  }

  &_showMoreActive {
    height: auto;
  }

  &_isAlignedTop {
    flex-direction: column-reverse;

    @include desktop-bp {
      flex-direction: row;
    }
  }
  &_isReverse {
    @include desktop-bp {
      flex-direction: row-reverse;
    }
  }
}

.textContentWrapper {
  width: 100%;

  @include desktop-bp {
    width: 45%;
    padding: 0 0 8% 0;
  }

  &_isAlignedBottom {
    @include desktop-bp {
      padding-bottom: 0;
      padding-top: 8%;
    }
  }
}

.background {
  width: 100%;
  height: 100%;
  padding-top: 100%;
  padding: 8% 5%;

  @include desktop-bp {
    padding: 10% 13%;
  }

  @include desktop-lg-bp {
    padding: 20% 25%;
  }

  &_readLessIsActive {
    height: auto;
  }
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;

  @include desktop-bp {
    gap: 24px;
  }

  &_readLessIsActive {
    justify-content: flex-start;
  }
}

.heading {
  @include heading4;

  @include desktop-bp {
    @include heading2;
  }
}

.subheading {
  @include paragraph-S;
  font-family: 'Proxima Nova';
  font-size: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.markdown {
  @include paragraph-M;
  font-family: 'Proxima Nova';
  font-size: 16px;

  @include desktop-bp {
    @include paragraph-L;
    font-family: 'Proxima Nova';
    font-size: 18px;
  }
}

.readMore {
  text-decoration: underline;
  margin-top: 10px;
  @include paragraph-L;
  font-family: 'Proxima Nova';
  font-size: 18px;
  cursor: pointer;
}

.buttonsWrapper {
  margin: 16px 0;
}

.button {
  padding: 16px 24px;
  @include paragraph-M;
  font-family: 'Proxima Nova';
  font-size: 16px;
  width: fit-content;
}

.imageContentWrapper {
  width: 100%;
  height: fit-content;
  transform: translateY(8%);

  @include desktop-bp {
    transform: none;
    width: 55%;
  }

  &_alignedTop {
    transform: translateY(-8%);
    @include desktop-bp {
      transform: none;
    }
  }

  &_showMoreActive {
    height: auto;
  }
}

.imageWrapper {
  aspect-ratio: 1/1;
  align-self: flex-start;
  position: relative;
  width: 95%;
  transform: translateY(-8%);

  @include desktop-bp {
    width: 100%;
    transform: none;
  }
  &_isAlignedTop {
    transform: translateY(8%);
    margin-left: 5%;
    @include desktop-bp {
      transform: none;
      margin-left: 0;
    }
  }
}

.image {
  object-fit: cover;
}
